import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/kszol/projects/noeadogatsby/src/components/layout/default-page-layout.tsx";
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Gallery from '../components/layout/gallery';
import SingleImage from '../components/layout/single-image';
import LightboxPageLayout from '../components/layout/lightbox-page-layout';
export const query = graphql`
  query {
    allFile(
      filter: {
        relativeDirectory: { eq: "erreforditjuk/2022" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            breakpoints: [210, 270, 370, 572, 720]
          )
          original {
            width
            height
          }
        }
      }
    }
    allJson(filter: { graphql: { eq: "EF2022" } }) {
      nodes {
        resources {
          index
          title
          image {
            base
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = LightboxPageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Erre fordítjuk, amit adsz (2022)`}</h1>
    <h2>{`Noé állatotthon`}</h2>
    <p>{`Túl vagyunk a NOÉ Állatotthon fennállásának 30 éves évfordulóján. Ez alatt az idő alatt rengeteg minden történt velünk. Megéltünk sok örömöt, átéltünk sok csodát és persze sírtunk is. Volt világméretű gazdasági válság, éppen hogy túlvagyunk történetünk első világjárványán, most pedig háború dúl mellettünk.`}</p>
    <p>{`A COVID, a háború és a kedvezőtlen gazdasági folyamatok napjainkban mindennél nagyobb kihívás elé állítják a civil szervezeteket, így minket is. Sajnos radikálisan csökkentek az adományok és érezhetően visszaesett az örökbefogadások száma. Mindeközben újabb és újabb kihívásokkal kell szembe néznünk: szívférgesség, parazitafertőzések, „rezsis” állatok visszakerülése, „bent ragadt” kölykök szocializációs nehézségei stb. Nem volt könnyű a 2022-es év, de már látjuk, hogy igazából csak előszobája volt egy hosszabb nehéz időszaknak. Muszáj ehhez alkalmazkodni, muszáj emiatt átgondolni és átszervezni az életünket.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(0, 3)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Ami nem változott, hogy továbbra is nagyon szorosan együttműködünk a többi állatvédő szervezettel és más civil szerveződésekkel. Alapító tagjai vagyunk az Állatvédők Érdekvédelmi és Szakmai Szövetségének. A Szövetség keretein belül, amikor felhívást kaptunk, részt vettünk jogszabályelőkészítési folyamatokban, lobbitevékenységet folytatunk az állatvédő szervezeteket érintő szabályozások (pl. benzinár, rezsi mértéke, stb.) pozitív alakítása érdekében. Sok tonna adományt gyűjtöttünk és juttattunk ki a háború sújtotta Ukrajnába. Illetve itt Magyarországon is segítjük a háború elől menekülőket, a BOK csarnokban működő Animal Aid Budapest segélyponton.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(3, 6)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`2022-ben is részt vettünk több szaporítótelep, állatgyűjtögető horror tanyák felszámolásbán. Néhány szörnyű helyszín, a teljesség igénye nélkül: Budafok, Bugac, 11. kerület... megannyi helyszín, a legtöbb helyen évek óta sínylődő állatok. Ezeket a helyszíneket csak közösen, több szervezettel együtt lehet felszámolni.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(6, 9)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Sajnos továbbra is tarol a szívférgesség és a giardia nevű parazita fertőzés. A bekerülő kutyák túlnyomó része érintett egyikben, vagy másikban, sok esetben mind a kettőben. A modern, fertőtleníthető kölyökkaranténak és a szigorú karanténozási és fertőtlenítési szabályainknak hála a kutyáknál a parvo megbetegedés visszaszorult. Viszont sajnos egyre gyakrabban kerülnek a gondozásunkba cica-parvoval (panleukopenia) fertőzött kiscicák.`}</p>
    <p>{`Továbbra is rengeteg balesetes, sérült és beteg állatnak kérnek tőlünk segítséget az ország minden pontjáról. Ahogy hellyel és anyagiakkal bírjuk megpróbálunk minél több életet megmenteni. Nehezíti a helyzetünket, hogy az adományok radikális visszaesése mellett, az állatorvosi költségek, még a jelentős kedvezményeink mellett is megsokszorozódtak. Nem beszélve a gyógytápok árairól. Ott tartunk, hogy 10-12kg speciáls hypoallergen, vagy egyéb diétás táp ára 30-40.000Ft.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(9, 12)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`A nehézségek nyilván nem csak minket sújtanak, tömve vannak a gyepmesteri telepek, hordákban portyáznak a kóbor kutyák. Kétségbeesetten kérlelik egymást az állatvédők, hogy ki az akinél van még egy talpalatnyi hely, akinek van még egy kis pénze állatorvosra. Ebben az időszakban nekünk is nagyon át kell gondolni, hogy mennyit tudunk vállalni, akkor is ha a nemet mondás minden esetben szívszaggató.`}</p>
    <p>{`2022-ben 956 állat került a gondozásunkba, ebből 704 kutya vagy cica. A többiek baromfik, disznók, kecskék, birkák, szarvasmarha, sérült madarak, vagy egyéb vadonélő fajok egyedei. 706 kutyát és cicát sikerült új családba örökbeadnunk. A vadonélő fajok egyedeit, ha egészségi állapotuk megengedi, akkor gyógyulásuk után szabadon engedjük, sokan önszántukból maradnak a NOÉ Állatotthon közelében. A gazdasági állatok és azok akik nem repatriálhatóak pedig végleges lakóinként élik tovább velünk az életüket.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(12, 15)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Jelen helyzetben a fejlesztések üteméből lényegesen vissza kellett venni. A nagy beruházásokat vagy leállítottuk, vagy csak nagyon lassan haladunk velük. Ilyen például a hátsó részleg vizesblokkja, az alap kész van, ahogy tudunk pici anyagi erőforrást átcsoportosítani, úgy haladunk vele lépésről lépésre.`}</p>
    <p>{`Természetesen vannak olyan nem halasztható karbantartások, átalakítások, melyek az állatok biztonsága és ellátása érdekében muszáj kivitelezni. Folyamatosa újítjuk fel a kenneleket, erősítjük meg az elválasztásokat. A hűtőkonténer, a főzőüstök, az etetők rendszeres karbantartást, időről időre cserét igényelnek.`}</p>
    <p>{`Sikerült befejezni a szénatárolót. Ez már halaszthatatlan volt, hiszen lovainknak és egyéb növényevőinknek a széna fő tápláléka. Igyekszünk mindig a legjobb minőséget beszerezni a számukra, a tárolás viszont eddig nagy probléma volt. Ha megázik, akkor berohad és már nem lehet megetetni. Éves szinten nagyjából 3 millió forint értékű szénát etetünk meg.`}</p>
    <p>{`És persze folyamatosan javítjuk, cseréljük, a közel 30 éves kennelsorainkat, kifutóinkat, hogy a nálunk rövidebb vagy hosszabb időt eltöltő állatok kényelemben, biztonságban lehessenek.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(15, 18)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Az álmainkat és terveinket természetesen nem adjuk fel, bár tudjuk, hogy 2023 extra nehéz év lesz Elsősorban szeretnénk befejezni a megkezdet projekteket, de nagyon szeretnénk egy modern, jól takarítható galambdúcot a mostani galambos faház helyére. Külső karanténunk felújítása, modernizálása is megoldásra vár, ahogy a felnőtt karantén is. Bővíteni szeretnénk a cicás karantén és gyengélkedő részlegünket. És óriási tervünk egy teljesen új, modern kórházépület felépítése.`}</p>
    <p>{`Menhelysegítő programunk továbbra is teljes gőzzel működik. Több ezer kg kutya és cicatáppal, macskaalommal, gumikesztyűvel tudtuk egész évben folyamatosan segíteni a kisebb menhelyeket. Gyógyszerek, gyógyászati segédszközök kerültek kiosztásra igény szerint.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(18, 21)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Volt sok szívszorító eset, amikor magunk sem hittük a látottakat. A bekerülő és a nálunk élő védenceinkről szóló hírek elolvashatóak ITT: `}<a href="http://www.noeallatotthon.hu/index.php?pid=2&tid=4" target="_blank" rel="noreferrer">{`Történetek Állatainkról`}</a></p>
    <p>{`2023`}{`.`}{` január 1-én az alapítvány védenceinek száma 1.194 volt.`}</p>
    <p>{`Hálásan köszönjük támogatóinknak, önkénteseinknek a rengeteg segítséget. Nagyon nehéz időszakban vagyunk, országosan hatalmas gondokkal küzd az állatvédelem. A radikálisan visszaesett támogatások, a csökkenő örökbeadás számok mellett minden segítség szó szerint életmentő ebben az időszakban.`}</p>
    <p>{`Évről évre a biztos alaphátteret a személyi jövedelemadójukat felajánlók biztosítják nekünk, amiért szintén nagyon hálásak vagyunk. Arra kérünk mindenkit, hogy ne hagyják veszni ezt a támogatási lehetőséget és rendelkezzenek 2022. május 20-ig személyi jövedelemadójuk 1%-ról!`}</p>
    <p><strong parentName="p">{`MERT A CSODÁKAT EGYÜTT CSINÁLJUK!`}</strong></p>
    <SingleImage resources={[props.data.allJson.nodes[0].resources[21]]} nodes={props.data.allFile.nodes} mdxType="SingleImage" />
    <p>{`Korábbi beszámolóink:`}</p>
    <p>
  <Link to="/erre-forditjuk-2021" mdxType="Link">Erre fordítjuk - 2021</Link>
    </p>
    <p>
  <Link to="/erre-forditjuk-2020" mdxType="Link">Erre fordítjuk - 2020</Link>
    </p>
    <p>
  <Link to="/erre-forditjuk-2019" mdxType="Link">Erre fordítjuk - 2019</Link>
    </p>
    <p>
  <Link to="/erre-forditjuk-2018" mdxType="Link">Erre fordítjuk - 2018</Link>
    </p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      